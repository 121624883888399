@import url(https://fonts.googleapis.com/css?family=Poppins:400,400i,600,600i&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f5f5f5;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: left;
  margin: 0;
  padding: 5vh;
  margin-bottom: 9vh;
}

input {
  -webkit-transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, -webkit-transform .1s ease;
  transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, -webkit-transform .1s ease;
  transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s ease;
  transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s ease, -webkit-transform .1s ease;
  color: #000;
  background-color: #fff;
  font-family: inherit;
  font-size: inherit;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 10px;
  border: none;
  border-radius: 1.5vh;
  outline: none
}

input:focus {
  box-shadow: 0 0 0 2px #0094FF;
}

.Search {
  width: 100%;
  height: 8vh; 

  position: relative;
  box-shadow: 0 1.2vh 1vh -1vh rgba(0,0,0,0.2);
}

.Search:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.UPCList {
  position: relative;
  /* top: 5vh; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: left;
  -webkit-animation: UC-Transition-In 0.2s linear;
          animation: UC-Transition-In 0.2s linear;
}

.UserPreviewCard {
  position: relative;
  width: 100vh;
  height: 20vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding: 10px;

  background: #fff;
  border-radius: 13px;
  box-shadow: 0 1.2vh 1vh -1vh rgba(0,0,0,0.2);
}

.UserPreviewCard:hover {
  /* box-shadow: 0 0 0 .5vh #0094FF; */
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.UPCContents {
  position: relative;
  padding: 5vh;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
}

.UPCProfilePicture {
  width: 12vh;
  height: 12vh;
  border-radius: 100%;
  background: #f0f0f0;
  flex-grow: 0;
  margin: 3vh;
  outline: none;
  border: none;
}

.UPCNameDiv {
  flex-grow: 1;
}

.UPCFullName {
  font-style: normal;
  font-weight: 600;
  font-size: 18pt;
  line-height: 4vh;
}

.UPCUsername {
  color: #bbbbbb;
  font-size: 10pt;
}

.UPCAddButton {
  height: 7vh;
  width: 7vh;
  background: #0094FF;
  border-radius: 542px;
  color: #ffffff;
  font-weight: bold;
  font-size: 2.5vh;
  outline: none;
  border: none;
}

.UPCAddButton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.UPCAddButton--disabled {
  height: 7vh;
  width: 7vh;
  background: #bbbbbb;
  border-radius: 542px;
  color: #ffffff;
  font-weight: bold;
  font-size: 2.5vh;
  outline: none;
  border: none;
}

/* --- */

.UCList {
  position: relative;
  /* top: 5vh; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: left;
}

.UserCard {
  position: relative;
  width: 100vh;
  min-height: 100vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding: 10px;

  background: #fff;
  border-radius: 13px;
  box-shadow: 0 1vh 1vh -1vh rgba(0,0,0,0.2);
  -webkit-animation: UC-Transition-In 0.2s linear;
          animation: UC-Transition-In 0.2s linear;
}

.UCContents {
  position: relative;
  padding: 5vh;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
}

.UCProfilePicture {
  width: 12vh;
  height: 12vh;
  border-radius: 100%;
  background: #f0f0f0;
  flex-grow: 0;
  margin: 3vh;
  outline: none;
  border: none;
}

.UCNameDiv {
  flex-grow: 1;
}

.UCFullName {
  font-style: normal;
  font-weight: 600;
  font-size: 18pt;
  line-height: 4vh;
}

.UCUsername {
  color: #bbbbbb;
  font-size: 10pt;
}

.UCAddButton--disabled {
  height: 7vh;
  width: 14vh;
  background: #bbbbbb;
  border-radius: 542px;
  color: #ffffff;
  font-weight: bold;
  font-size: 2.5vh;
  outline: none;
  border: none;
}

.UCBio {
  padding: 2vh;
}

.Nav {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
  
  height: 10vh;
  overflow: hidden;
  position: fixed;
  bottom: 2vh;
  padding: 2vh;
  width: 90%;
  border-radius: 2vh;
  z-index: 1;
  box-shadow: 0 1.2vh 1vh -1vh rgba(0,0,0,0.5);
}

.Nav a {
  float: inherit;
  display: block;
  color: #000000;
  text-align: center;
  text-decoration: none;
  font-size: 15pt;
}

.Nav a:hover {
  color: #0094FF;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.Nav svg {
  color: #0f0f2f;
}

.Nav svg:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

@media only screen and (max-width: 738px) {
  .App {
    padding: 2vh;
    margin-bottom: 11vh;
  }

  .Search {
    box-shadow: 0 1.2vh 1vh -1vh rgba(0,0,0,0.2);
  }

  .Search:hover {
    -webkit-transform: none;
            transform: none;
  }

  .UserPreviewCard {
      max-width: 47vh;
  }

  .UserPreviewCard:hover {
    box-shadow: none;
    -webkit-transform: none;
            transform: none;
  }

  .UPCContents {
      padding: 0;
  }

  .UPCProfilePicture {
      width: 8vh;
      height: 8vh;
      border-radius: 100%;
      margin-right: 2vh;
      outline: none;
      border: none;
    }

    /* .UPCNameDiv {

    } */

    .UPCFullName {
      font-style: normal;
      font-weight: 600;
      font-size: 2.5vh;
      line-height: 4vh;
    }
    
    .UPCUsername {
      color: #bbbbbb;
      font-size: 10pt;
    }

    .UPCAddButton, .UPCAddButton--disabled {
      width: 7vh;
      height: 7vh;
      margin-right: 2vh;
      font-size: 2vh;
      outline: none;
      border: none;
    }


    .UserCard {
      max-width: 47vh;
  }

  .UCContents {
      padding: 0;
  }

  .UCProfilePicture {
      width: 8vh;
      height: 8vh;
      border-radius: 100%;
      margin-right: 2vh;
      outline: none;
      border: none;
    }

    /* .UCNameDiv {

    } */

    .UCFullName {
      font-style: normal;
      font-weight: 600;
      font-size: 2.5vh;
      line-height: 4vh;
    }
    
    .UCUsername {
      color: #bbbbbb;
      font-size: 10pt;
    }

    .UCAddButton, .UCAddButton--disabled {
      width: 12vh;
      height: 6vh;
      margin-right: 2vh;
      font-size: 2vh;
      outline: none;
      border: none;
    }
}

@-webkit-keyframes UC-Transition-In {
  0%{
      -webkit-transform: scale(.98) translateY(3vh);
              transform: scale(.98) translateY(3vh);
      opacity: 0;
      }
}

@keyframes UC-Transition-In {
  0%{
      -webkit-transform: scale(.98) translateY(3vh);
              transform: scale(.98) translateY(3vh);
      opacity: 0;
      }
}
